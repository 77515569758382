.debt_file_list{
    text-align: center;
    margin-top: 30px;
    width: 191px;
}

.debt_file_item{
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
}

.debt_file_delete_icon{
    cursor: pointer;
    height: 18px;
    width: 12px;
}

.debt_file_item_text{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #05BE8C;
    cursor: pointer;
}