body {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  display: block;
  border: none;
  height: 80vh;
  width: 100%;
  margin-bottom: 5vh;
}

.bold {
  font-weight: bold;
}
.blue {
  color: #014083;
}
.red {
  color:  #DC3545;
}
.grey {
  color:  #4A4A4A;
}

.green {
  color:  #28A745;
}


.blue-light {
  color: #0169e4;
}
.ramp {
  margin: 0 !important;
  padding: 0 !important;
}
.ramp2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.title {
  color: #014083;
  margin-top: 0px;
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 24px;
}
.font15 {
  font-size: 15px;
}
.font14 {
  font-size: 14px;
}
.font13 {
  font-size: 13px;
}
.font12 {
  font-size: 12px;
}

/* Estilo personalizado scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #e1e1e1;
}

::-webkit-scrollbar:vertical {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #898989;
  border-radius: 2px;
}

/* Estilos personalizados modal ant-design */
.ant-modal {
  padding-bottom: 0px !important;
}
.ant-modal-content {
  background-color: #f0f4f9 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px !important;
}

.ant-modal-header {
  background-color: #f0f4f9 !important;
  border-bottom: none !important;
  padding: 16px 24px 10px 24px !important;
}

.ant-modal-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22px !important;
  color: #014083 !important;
}

.ant-modal-close-x {
  outline: none !important;
  color: #0169e4 !important;
  font-weight: 700 !important;
  line-height: 45px !important;
  padding: 0rem 1rem 0rem 0rem !important;
}

.ant-modal-close-icon {
  font-size: 20px !important;
  font-weight: 700 !important;
}

/* Estilos personalizados upload ant-design */
.ant-upload > .ant-btn {
  border: 1px solid rgba(95, 111, 129, 0.7) !important;
}
.anticon-upload {
  vertical-align: 0.125em !important;
}
.ant-upload-list,
.ant-upload-list-text {
  display: none !important;
}

/* Estilos personalizados input-select-radio-datePicker-checkbox ant-design */
.ant-input,
.ant-picker {
  height: 32px;
  border: 1px solid rgba(95, 111, 129, 0.5) !important;
}
.ant-picker-clear {
  visibility: hidden;
}
.ant-select,
.ant-select-single,
.ant-select-show-arrow {
  height: 32px !important;
  /* border: 1px solid white !important; */
}
.ant-select-selector {
  height: 32px !important;
  border: 1px solid rgba(95, 111, 129, 0.5) !important;
}
.ant-radio-wrapper {
  white-space: normal !important;
}
.ant-picker-input > input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input),
.ant-select-selector,
.ant-radio-disabled + span {
  color: #212529 !important;
}

.ant-input[disabled] {
  color: black !important;
  opacity: 0.7 !important;
}
.ant-input-group-addon {
  padding: 0 5px !important;
  background-color: #fff !important;
  border: 1px solid rgba(95, 111, 129, 0.5) !important;
  border-right: none !important;
}
.ant-checkbox-wrapper,
.ant-checkbox-group {
  margin-bottom: 10px !important;
}

/* Estilos personalizados tabla ant-design */
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

/* Estilos personalizados Skeleton */
.css-1eg0sfo-skeletonStyles-Skeleton {
  background-color: #fff !important;
}

.projectEditpen{
  font-family: "Mulish";
  font-size: 10px;
  font-weight: 400;
  line-height: 12.55px;
  cursor: pointer;
}