.payments_validation_panel{
    width:80px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.payment_action_active{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.payment_action{
    width: 15px;
    height: 15px;
}