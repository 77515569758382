.debt_charge_file{
    border: solid 1px #014083;
    width: 185px;
    height: 32px;
    text-align: center;
    margin-top: 30px;
    padding-top: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
}

.debt_charge_file_text{
    color:  #014083;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}