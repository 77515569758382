.add_payment_button{
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 2rem;
    height: 2rem;
    border: 2px solid #0169E4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0169E4;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
}