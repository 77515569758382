.valorizations {
  height: calc(100vh - 353px);
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fff;
}

.valorization__workspaceDiv {
  display: flex;
  flex-direction: column;
}
.valorization__workspace {
  display: flex;
  /* position: sticky; */
  top: 0;
}

.valorization_workspace_header {
  display: flex;
  position: sticky;
  top: 0;
}

.valorization__partList {
  /* width: 616px; */
  display: flex;
  flex-direction: column;
}

.valorization__partHeaders-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
}

.valorization__partHeaders {
  display: flex;
}

.valorization__partHeaders-base {
  display: flex;
  height: 28px;
}
.sc-fvtFIe .chFswI {
  width: 222px;
}

button.dropdown-toggle.btn.btn-secondary {
  background-color: transparent;
  color: #808080;
  height: 28px;
  padding: 1px, 6px;
  font-size: 12px;
  box-sizing: border-box;
}

.valorization__headerItem {
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 2px #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.valorization__searchContainer {
  width: 350px;
  border-right: solid 2px #f3f3f3;
  padding-top: 4px;
}

.valorization__searchInput {
  font-size: 12px;
  width: 280px;
  color: black;
  margin-top: 1px;
  margin-right: 10px;
  border: white solid 1px;
  outline: none;
}

.valorization__budget {
  width: 266px;
  border-right: solid 1px #f3f3f3;
}

.valorization__partHeaders-index {
  height: 40px;
  background: white;
  text-align: center;
  border-bottom: solid 1px #efefef;
  padding-top: 1px;
}

.valorization__partHeaders-text {
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}

.valorization__valList {
  display: flex;
  flex-direction: column;
}

.valorization__valHeaders {
  display: flex;
  flex-direction: row-reverse;
}

@media (min-width: 667px) and (max-width: 1257px) {
  .valorizations {
    height: calc(100vh - 506px);
  }
}

@media (max-width: 666px) {
  .valorizations {
    height: calc(100vh - 99px - 127px);
  }
}
