.navbar__menu_icon {
  margin-top: 10px;
}

/* Project value - provisional */
.navbar__menu-icon_active {
  margin-top: 12px;
  cursor: pointer;
}

.navbar__search {
  height: 32px;
  width: 201px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin: auto 0px;
}
.navbar__search__container-icon {
  background-color: #00c187;
  height: 32px;
  width: 41px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar__search__icon {
  margin-top: 4px;
}

.navbar__search__input {
  width: 160px;
  border: 1px solid rgba(95, 111, 129, 0.2);
  border-radius: 4px 0px 0px 4px;
  padding: 8px;
  background: #ffffff;
  outline: none;
}

.navbar__project__btn {
  background-color: #0169e4;
  cursor: pointer;
  text-align: center;
  color: white;
  margin: auto 20px;
  width: 157px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  padding-top: 3px;
}

.navbar__project__btn-text {
  font-size: 16px;
  line-height: 18px;
  margin-top: 3px;
  margin-left: 6px;
}

.navbar__dropdown_bell {
  min-width: 300px !important;
  max-height: 50vh !important;
  overflow-y: auto;
  font-size: 14px !important;
  padding: 0px !important;
  font-weight: 500;
  transform: translate3d(-200px, 40px, 0px) !important;
  position: absolute !important;
  z-index: 999 !important;
  left: -50px !important;
}

.navbar__notification_white {
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border-radius: 50%;
  border-color: #004283;
  border-style: solid;
  border-width: 2px;
  margin: auto 20px auto 0px;
}

.navbar__notification__icon {
  cursor: pointer;
}

.navbar__notification__timestamp {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.navbar__notification__name {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  color: #0169e4;
}

.navbar__notification__active {
  background-color: #f0f4f9;
  padding: 5px !important;
  left: -50px !important;
}

.navbar__notification__deactive {
  background-color: #ffffff;
  padding: 5px !important;
  left: -50px !important;
}

@media (max-width: 640px) {
  .navbar__project__btn-text {
    display: none;
  }
  .navbar__project__btn {
    width: 50px;
  }
}

@media (max-width: 520px) {
  .navbar__search__input {
    display: none;
  }
  .navbar__search {
    width: 50px;
    height: 100%;
  }
  .navbar__search__icon {
    width: 32px;
    height: 32px;
    margin-top: 0px;
  }
  .navbar__search__container-icon {
    height: 100%;
    width: 50px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar__project__btn {
    height: 100%;
    border-radius: 0px;
    align-items: center;
    padding-top: 0px;
    margin-left: 0px;
  }
}

@media (max-width: 380px) {
  .navbar__project__btn {
    margin-right: 15px;
  }
}
