.parts__index {
  width: 86px;
  height: 40px;
  background-color: white;
  text-align: left;
  padding-left: 10px;
  border-bottom: solid 1px #efefef;
}

.parts__firstJerarchy {
  background-color: #efefef !important;
  font-weight: bold;
}

.parts__secondJerarchy {
  background-color: #edfffa !important;
}

.parts__text {
  font-size: 12px;
  color: black;
  margin-top: 10px;
}

.parts__contract-div {
  width: 16px;
  height: 40px;
  background-color: white;
  text-align: left;
  border-bottom: solid 1px #efefef;
}

.parts__contract-button {
  width: 14px;
  height: 14px;
  font-size: 11px;
  margin-top: 12px;
  border: solid 1px gray;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parts__name-div {
  width: 248px;
  height: 40px;
  background-color: white;
  text-align: left;
  padding-left: 10px;
  border-bottom: solid 1px #efefef;
}

.parts__name-text {
  font-size: 12px;
  color: black;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.parts__tab__firstJerarchy {
  padding-left: 20px !important;
}

.parts__tab__secondJerarchy {
  padding-left: 30px !important;
}

.parts__content {
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 1px #efefef;
}

.parts__input {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin-top: 8px !important;
  width: 50px;
  text-align: center;
  border-left: 1px white solid;
  border-right: 1px white solid;
  border-top: 1px white solid;
  border-bottom: 1px #004283 solid;
}

.parts__input:disabled {
  border-bottom: 1px #cccbcb solid;
}

.parts__pointer {
  cursor: pointer;
}

/* Image Modal */
.imageModal__btns a {
  text-decoration: none;
  text-decoration-style: none;
  color: white;
}

.imageModal__btns a:hover {
  text-decoration: none;
  text-decoration-style: none;
  color: white;
}

.imageModal__btns {
  display: flex;
  gap: 30px;
  align-self: center;
  justify-content: center;
}

.imageModal__image {
  text-align: center;
  height: 250px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 200px;
  height: 230px;
}
