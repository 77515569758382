.addMateCard_container {
  width: 220px;
  min-height: 358px;
  background: #ffffff;
  border: 1px dashed rgba(95, 111, 129, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
}

.addMateCard_header {
  width: 218px;
  height: 68px;
  background: #f0f4f9;
  border-radius: 3px 3px 0px 0px;
}
.addMateCard_containerImg {
  width: 126px;
  height: 126px;
  margin: auto;
  margin-top: -56px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid rgba(95, 111, 129, 0.7);
  text-align: center;
  padding-top: 32px;
}

.addMateCard_body {
  padding: 8px 12px;
  font-size: 12px;
  text-align: center;
}
.addMateCard_containerIcon {
  width: 46px;
  height: 46px;
  margin: auto;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid rgba(95, 111, 129, 0.7);
  text-align: center;
  padding-top: 13px;
}
.addMateCard_name {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
}

.addMateCard_position {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.addMateCard_advice {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #5f6f81;
}

.addMateCard_bold {
  font-weight: bold;
}
