.valorization_summary_table_container{
    margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.valorization_summary__card__container {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    background: #fff;
    padding: 15px 12px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .valorization_summary__card__container_mobile {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    background: #fff;
    padding: 15px 12px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 447px;
  }