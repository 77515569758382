.editProjectMates__container {
  display: flex;
  gap: 40px;
}

.editProjectMates__collaborators {
  width: 515px;
  max-height: 1400px;
}
.editProjectMates__collaborators__container {
  justify-content: space-between;
  overflow-y: auto;
  max-height: 1270px;
}
.editProjectMates__team {
  width: calc(100% - 555px);
}

/* media queries */
@media (max-width: 1125px) {
  .editProjectMates__collaborators {
    width: 280px;
  }
  .editProjectMates__team {
    width: calc(100% - 320px);
  }
}

@media (max-width: 660px) {
  .editProjectMates__container {
    flex-direction: column;
    gap: 10px;
  }
  .editProjectMates__collaborators {
    width: 100%;
  }
  .editProjectMates__team {
    width: 100%;
  }
}
