.mateCard_container {
  width: 220px;
  background: #ffffff;
  border: 1px solid rgba(95, 111, 129, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}

.mateCard_leader {
  position: absolute;
  width: 63px;
  height: 24px;
  right: -1px;
  top: -1px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.mateCard_header {
  width: 218px;
  height: 68px;
  border-radius: 3px 3px 0px 0px;
}
.mateCard_containerImg {
  width: 126px;
  height: 126px;
  margin: auto;
  margin-top: -56px;
  border-radius: 50%;
}

.mateCard_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mateCard_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 12px;
  font-size: 12px;
  height: calc(100% - 138px);
}
.mateCard_name {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
}

.mateCard_position {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.mateCard_flex {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.mateCard_secondItem {
  text-align: right;
  /* overflow-wrap: anywhere; */
  word-break: break-word;
  hyphens: auto;
}

.mateCard_bold {
  font-weight: bold;
}

.mateCard_btn {
  /*   padding: 0.3rem 1rem !important; */
  font-size: 14px !important;
  margin-bottom: 10px !important;
}
