.projectVal__mb1 {
  margin-bottom: 5px !important;
}

.projectVal__mb2 {
  margin-bottom: 10px !important;
}

.projectVal__mb4 {
  margin-bottom: 16px !important;
}

/* QROME */
.projectVal__icon {
  height: 26px;
  width: 26px;
  border: 1px solid #014083;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -8px;
  cursor: pointer;
}

.projectVal__userContainer {
  display: flex;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}

.projectVal__qrome {
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px !important;
}

.projectVal__btnsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projectVal__valCard-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #0169e4;
  margin-bottom: 5px;
}

.projectVal__contentDiv {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.projectInfo__contentDiv {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  margin-bottom: -4px;
}

.projectVal__timerContainer {
  /* justify-content: space-between;
  gap: 5px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  overflow-x: auto;
}



.projectVal__BottomRowDiv{
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

.projectVal__BottomColummDiv{
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  margin: 0px 5px 0px 5px;
}



.projectVal__timerCard {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.projectVal__image {
  width: 200px;
  height: 140px;

}

.projectVal__image-min {
  display: none;
  width: 160px;
  height: 140px;
}

/* Modal */
.projectVal__modal__description {
  margin-top: -15px;
}

.projectVal__modal__cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 450px;
  overflow-y: auto;
  margin-bottom: 18px;
}

.projectVal__card__container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  background: #fff;
  padding: 15px 12px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.projectVal__card__items {
  display: flex;
  justify-content: space-between;
}

.projectVal__card__output {
  height: 32px;
  background: #f3f3f3;
  border-radius: 2px;
  padding: 4px 12px;
  font-size: 14px;
}

.projectVal__card__image {
  width: 269px;
  height: 155px;
}

.projectVal__card__editImg {
  position: relative;
  bottom: 30px;
  width: 60px;
  background: #ffffff;
  width: 30px;
  height: 25px;
  border: 1px solid rgba(95, 111, 129, 0.7);
  border-radius: 50%;
}
.projectVal__card__delete {
  left: 205px;
}

.projectVal__modal__footer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 0px 12px 0px 0px;
  border-radius: 4px;
}

.projectVal__modal__btns {
  display: flex;
  gap: 30px;
  align-self: center;
  /* justify-content: flex-end; */
  justify-content: center;
  margin: auto;
}

@media (min-width: 1258px) and (max-width: 1432px) {
  .projectVal__image {
    display: none;
  }
}

@media (max-width: 823px) {
  .projectVal__image {
    display: none;
  }
}

@media (max-width: 666px) {
  .projectVal__image-min {
    display: block;
  }
}
