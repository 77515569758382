.no_debt_icon{
    width: 122px;
    height: 105px;
}

.no_debt_indication{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #014083;
}