/* Estilos generales */
.editProject__rowCards {
  display: flex;
  justify-content: space-evenly;
}

.editProject__header{
  display: flex;
  justify-content: space-between;
  margin: 2rem 0rem 0rem 0rem !important;
  padding: 0 !important;
}

.editProject__editIcon {
  margin-left: 10px;
  margin-top: -8px;
  border-bottom: 1px solid #0169e4;
}
.editProject__inputName {
  background-color: transparent;
  border: none;
  outline: none;
  color: #014083;
  font-weight: bold;
  font-size: 24px;
  width: 90%;
}
.editProject__mb0 {
  margin-bottom: 0;
}
.editProject__mb4 {
  margin-bottom: 30px !important;
}
.editProject__mb2 {
  margin-bottom: 15px !important;
  
}
.editProject__ml {
  margin-left: 15px !important;
}
.editProject__mr {
  margin-right: 15px !important;
}
.editProject__underline {
  text-decoration-line: underline;
  cursor: pointer;
}
.editProject__green {
  color: #05be8c;
}
.editProject__red {
  color: #d93022;
}

/* Basic Information */
.editProject__basicInfo__imgContainer {
  width: 294px;
  height: 294px;
  background: #ececec;
  border: 1px solid rgba(95, 111, 129, 0.7);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editProject__basicInfo__image {
  width: 294px;
  height: 294px;
}
.editProject__basicInfo__editImg {
  position: relative;
  margin-top: -40px;
  margin-bottom: 30px;
  margin-left: 250px !important;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(95, 111, 129, 0.7);
  border-radius: 50%;
}
.editProject__basicInfo__editImgIcon {
  margin-left: 4px;
  margin-top: 3px;
}
.editProject__basicInfo__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
}
.editProject__basicInfo__addressInput {
  background: #ebebeb !important;
  width: 100% !important;
  height: 36px !important;
  border: none !important;
}

/* Project Team */
.editProject__team__container {
  margin-left: 15px;
}
.editProject__team__containerCards {
  display: flex;
  min-height: 57px;
  width: 95%;
  overflow-x: auto;
  margin-bottom: 15px !important;
  margin-bottom: 15px !important;
}

/* Calendar */
.editProject__calendar__container {
  margin-left: 15px;
}
.editProject__calendar__btnContainer {
  width: 90%;
  margin-bottom: 25px !important;
  gap: 15px;
}
.editProject__calendar__holidays-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}
.editProject__calendar__container-table {
  width: 400px;
  height: 176px;
  border: 1px solid rgba(95, 111, 129, 0.7);
  border-radius: 4px;
  overflow-y: auto;
}

/* Valuation Documents */
.editProject__valDocuments__container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  word-break: break-word;
  gap: 4px;
}

.editProject__valDocuments__secondItem {
  justify-self: end;
}

/* Commercial Terms */
.editProject__commercialTerms__container {
  justify-content: space-between;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.editProject__commercialTerms__retention {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

/* Initial Conditions */
.editProject__initConditions__container {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  font-size: 15px;
  margin: 0 auto;
}
.editProject__center {
  justify-self: center;
}
.editProject__initConditions__icons {
  display: flex;
  flex-direction: column;
}

.editProject__initConditions__input {
  padding: 4px 8px !important;
}

/* Modals */
.editProject__modals__container {
  display: flex;
  align-items: center;
}

.editProject__modals__btnContainer {
  display: flex;
  justify-content: center;
  row-gap: 20px;
  column-gap: 50px;
}

/* media queries */
@media (min-width: 1360px) {
  .editProject__basicInfo {
    width: 27%;
  }
  .editProject__team {
    width: 35%;
  }
  .editProject__calendar {
    width: 35%;
  }
  .editProject__valDocuments {
    width: 27%;
  }
  .editProject__commercialTerms {
    width: 32%;
  }
  .editProject__debtSettings {
    width: 32%;
  }
  .editProject__initConditions {
    width: 38%;
  }
}
@media (max-width: 1360px) and (min-width: 576px) {
  .editProject__basicInfo {
    min-width: 364px;
  }
  .editProject__team {
    min-width: 495px;
  }
  .editProject__calendar {
    width: 495px;
  }
  .editProject__valDocuments {
    min-width: 430px;
  }
  .editProject__commercialTerms {
    min-width: 429px;
  }
  .editProject__debtSettings {
    min-width: 429px;
  }
  .editProject__initConditions {
    min-width: 528px;
  }
}
@media (max-width: 575px) {
  .editProject__team__container,
  .editProject__calendar__container {
    margin-left: 0px;
  }
  .editProject__basicInfo,
  .editProject__team,
  .editProject__calendar,
  .editProject__valDocuments,
  .editProject__commercialTerms,
  .editProject__debtSettings,
  .editProject__initConditions,
  .editProject__team__containerCards {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .editProject__calendar__btnContainer {
    width: 100%;
  }
  .editProject__calendar__holidays-header {
    width: 100%;
  }
  .editProject__calendar__container-table {
    width: 100%;
    overflow-x: auto;
  }
  .editProject__calendar__table {
    width: 350px !important;
  }
  .editProject__initConditions__container {
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-gap: 10px;
  }
}
