.notifications_dropdown_menu{
  min-width: 300px !important;
  max-height: 50vh !important;
  overflow-y: auto;
  font-size: 14px !important;
  padding: 0px !important;
  font-weight: 500;
  transform: translate3d(-200px, 40px, 0px) !important;
  position: absolute !important;
  z-index: 999 !important;
  left: -50px !important;
}

.notifications_dropdown_container{
    margin: auto 0px;
}