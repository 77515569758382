.debt_section_selector {
  display: flex;
  flex-direction: row;
}

.debt_section_selector_item{
    background-color: #dddcdc;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: min-content;
    cursor: pointer;
}

.debt_section_selector_active {
  background-color: white;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: min-content;
  font-weight: bold;
}

.debt_section_selector_text {
  margin-bottom: 0px;
}
