.editProject__valSched {
  width: 100%;
  font-size: 15px;
}

.editProject__valSched__container {
  display: flex;
  justify-content: space-between;
}

.editProject__valSched__gridVal3 {
  display: grid;
  grid-template-columns: 5fr 4fr;
  align-items: center;
}

.editProject__valSched__gridVal2 {
  display: grid;
  grid-template-columns: 4fr 5fr;
  align-items: center;
}

.editProject__valSched__gridVal1 {
  display: grid;
  grid-template-columns: 3fr 4fr;
  align-items: center;
}

/* media queries */
@media (min-width: 1360px) {
  .editProject__valSched__section1 {
    width: 50%;
  }
  .editProject__valSched__section2 {
    width: 53%;
    padding-left: 40px !important;
  }
  .editProject__valSched__section1-container {
    border-right: 6px solid #f0f4f9;
  }
}

@media (max-width: 1360px) and (min-width: 576px) {
  .editProject__valSched {
    width: 100%;
  }
  .editProject__valSched__container {
    flex-direction: column;
    width: 100%;
  }
  .editProject__valSched__section1 {
    width: 100%;
  }
  .editProject__valSched__section2 {
    width: 100%;
    border-left: none;
  }
}
@media (max-width: 700px) {
  .editProject__valSched__gridVal1 {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .editProject__valSched__gridVal2 {
    grid-template-columns: 2fr 1fr;
  }
  .editProject__valSched__gridVal3 {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 575px) {
  .editProject__valSched__container {
    flex-direction: column;
  }
}
