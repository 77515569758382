.timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.5rem
}

@media (min-width:768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: "";
        display: block;
        border-top: .25rem dotted #3b82f6;
        width: 2rem;
        position: absolute;
        left: 6.5rem;
        top: .3125rem
    }
    .timeline-steps .timeline-step:not(:first-child):before {
        content: "";
        display: block;
        border-top: .25rem dotted #3b82f6;
        width: 2rem;
        position: absolute;
        right: 6.5rem;
        top: .3125rem
    }
}

.timeline-steps .timeline-content {
    width: 5.9rem;
    text-align: center;
    font-size: 0.75rem;
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 0.5rem;
    width: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3b82f6
}

.timeline-steps .timeline-content .inner-circle:before {
    content: "";
    background-color: #3b82f6;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    border-radius: 6.25rem;
    opacity: .5
}