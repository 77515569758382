.payments_table_items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border: 1px solid rgba(95, 111, 129, 0.7);
    width: 555px;
}

.payments_table_items_min{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border: 1px solid rgba(95, 111, 129, 0.7);
    width: 449px;
}

.payments_status_validated{
    color:#05BE8C;
}

.payments_status_rejected{
    color:#D93022;
}