.editProjectCard__title {
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  background: #F0F4F9;
  font-weight: 800;
  width: fit-content !important;
  padding: 0 0.5rem;
}

.editProjectCardSubtitle__title {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  font-weight: 800;
  border-bottom: 2px solid rgba(95, 111, 129, 0.7);
  width: fit-content !important;
}

.search {
  height: 32px;
  width: 201px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin: auto 0px;
}

.search_container_icon {
  height: 32px;
  width: 30px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid rgba(95, 111, 129, 0.5);
  border-left: none;
}

.search_icon {
  margin-top: 4px;
}

.search_input {
  width: 160px;
  border: 1px solid rgba(95, 111, 129, 0.5);
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  padding: 8px;
  background: #ffffff;
  outline: none;
}
