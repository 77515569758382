.headerVal__sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
}
.headerVal__subheader {
  display: flex;
}
.headerVal__headerMain {
  /* width: 1471px; */
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 2px solid #0169e4;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.headerVal__arrowIicon {
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.headerVal__arrowIicon-hidden {
  color: #fff;
  cursor: auto;
}
.headerVal__arrowIicon-left {
  margin-left: 20px;
}
.headerVal__arrowIicon-right {
  margin-right: 20px;
}

.headerVal__secondHeader {
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 2px #f3f3f3;
  border-right: solid 2px #f3f3f3;
  display: flex;
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.headerVal__valItems {
  width: 219px;
  padding-top: 4px;
  justify-content: center;
  align-items: center;
}

.headerVal__secondaryValItems {
  width: 265px;
  padding-top: 4px;
  justify-content: center;
  align-items: center;
}

.headerVal__thirdvalItems {
  width: 150px;
  padding-top: 4px;
  justify-content: center;
  align-items: center;
}

.headerVal__user {
  padding-top: 1px;
  justify-content: space-between;
}
.headerVal__profileImg {
  margin-left: 8px;
  margin-top: 4px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.headerVal__text {
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}
.headerVal__content {
  height: 40px;
  background-color: white;
  text-align: center;
  border-bottom: solid 1px #efefef;
  padding-top: 1px;
}
.headerVal__alignCenter {
  text-align: center;
}
.headerVal__dropdown {
  min-width: 150px !important;
  font-size: 14px !important;
  padding-left: 5px !important;
  font-weight: 500;
}
.headerVal__dropdown__button {
  margin-bottom: 10px;
  width: 60px;
  height: 22px;
  font-size: 10px;
  color: white;
  border: solid 1px white;
  border-radius: 5px 5px 5px 5px;
}
.headerVal__dropdown__buttonAll {
  background-color: #0069d9;
}
.headerVal__dropdown__buttonNone {
  background-color: #5a6268;
}
.headerVal__dropdown__button-mr {
  margin-right: 5px !important;
}
