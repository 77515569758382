.valorizations_summary_table_headers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 551px;
}

.valorizations_summary_table_headers_mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 423px;
}