.ramp {
  margin: 0 !important;
  padding: 0 !important;
}

.projectCard {
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
}

.projectCard__image {
  width: 200px;
  height: 170px;
  border-radius: 5px 0px 0px 5px;
}

.projectCard__content {
  background-color: white;
  padding-right: 10px;
  height: 170px;
  width: 140px;
  border-radius: 0px 5px 5px 0px;
}

.projectCard__title {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  color: gray;
  font-weight: 650;
  font-size: 15px;
}

.projectCard__date {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 15px;
  font-weight: 600;
  color: #0063d5;
  font-size: 12px;
}

.projectCard__status {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

.projectCard__success {
  color: #28a745;
}

.projectCard__error {
  color: #df4e4e;
}

.projectCard__bar {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 90%;
}

.projectCard_direction {
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  margin-left: 15px;
  margin-top: 10px;
  font-weight: 600;
  color: #0063d5;
}

.projectCard_direction-icon {
  margin-top: -15px !important;
}

.projectCard_direction-text {
  margin-left: 5px;
  margin-top: -3px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.projectCard__settings-cont {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
}
.projectCard__settings {
  width: 200px;
}

.projectCard__settings-item {
  color: gray;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}
.projectCard__settings-icon {
  margin-right: 5px;
}

@media screen and (max-width: 370px) {
  .projectCard__image {
    width: 170px;
  }
  .projectCard__content {
    width: 130px;
  }
}
