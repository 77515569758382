.breadcrumb_container {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0rem !important;
    margin-bottom: 0 !important;
    align-items: center;
    background-color: #FFFFFF !important;
}

.breadcrumb_item {
    margin: 0px 12px 0px 12px !important;
    background-color: #FFFFFF !important;
    font-size: 12px;
    font-family: "Mulish";
}


.breadcrumb__link {
    color: #000000 !important;
}

