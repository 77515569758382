.valorizations_summary_table_item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 551px;
}

.valorizations_summary_table_item_mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 423px;
}

.valorization_summary_button{
    border: #014083 solid 1px;
    padding: 4px 0px 4px 0px;
    cursor: pointer;
}

.valorization_summary_button_active{
    border: #014083 solid 1px;
    background-color: #014083;
    padding: 4px 0px 4px 0px;
    color:white
}